import React from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import UploadFilesForm from "../Forms/UploadFilesForm";

const YuklenimKaydi = ({ tckn }) => {
  return (
    <Box>
      <Grid container>
        <Grid item>
          <UploadFilesForm
            tckn={tckn}
            setData={false}
            url="api/reader_3/upload_files"
            secondUrl={"api/yuklenilecek_kdv/process_excel_files/"}
            title="Yüklenim Kaydı Yükle"
            folderType={"yuklenim"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default YuklenimKaydi;
