import { Grid, useTheme } from "@mui/material";
import React, { useEffect, useRef } from "react";
import MyButton from "./MyButton";

const FullScreen = ({ children }) => {
  const theme = useTheme();
  const ref = useRef();
  const makeFullscreen = () => {
    const elem = ref.current;
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        // Safari
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        // IE11
        elem.msRequestFullscreen();
      }
      elem.classList.add("fullscreen");
    }
  };
  useEffect(() => {
    const handleFullscreenChange = () => {
      const elem = ref.current;
      if (document.fullscreenElement) {
        elem?.classList.add("fullscreen");
      } else {
        elem?.classList.remove("fullscreen");
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);
  return (
    <>

      <Grid
        container
        justifyContent="end"       
        mt={2}
      >
        <Grid item my={1}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={makeFullscreen}
            title="Tam Ekran Görüntüle"
          />
        </Grid>
      </Grid>
      <div style={{
          backgroundColor: theme.palette.tableColor.main,
          width: "100%",
          height: "100%",
        }} ref={ref}>{children}</div>
    </>
  );
};

export default FullScreen;
