import { useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from "@mui/icons-material/Password";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import useAuthCall from "../hooks/useAuthCall";

const Login = () => {
  const { login } = useAuthCall();
  const [values, setValues] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleForgot = () => {
    window.open(
      process.env.REACT_APP_PASSWORD_RESET_URL ||
        "https://www.musavironline.com/api/user/password_reset/",
      "_blank"
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await login(values);
      setValues({ email: "", password: "" });
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        overflowY: "auto",
        margin: 0,
        padding: 0,
        background: "linear-gradient(to right, #e0f7fa, #ffffff)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          padding: 2,
          marginTop: 3,
        }}
      >
        <Typography variant="h4" color="#333333" fontWeight="bold">
          MÜŞAVİR ONLİNE'A HOŞGELDİNİZ
        </Typography>
      </Box>

      <Grid
        container
        sx={{
          flex: 1,
          height: "auto",
          margin: 0,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 3,
          }}
        >
          <Box
            component="img"
            src="https://www.musavironline.com/static/assets/img/main.avif"
            alt="Müşavir Online Görseli"
            sx={{
              width: { xs: "80%", sm: "60%" },
              height: "auto",
              borderRadius: 4,
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: 2, sm: 4 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: { xs: 300, sm: 400 },
              backgroundColor: "#ffffff", // Light ve dark mod uyumu için sabit renk
              color: "#333333",
              padding: { xs: 3, sm: 5 },
              borderRadius: 4,
              boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Avatar
              sx={{
                backgroundColor: "#0092CA",
                m: "auto",
                width: 60,
                height: 60,
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)",
              }}
            >
              <LockIcon sx={{ color: "white" }} />
            </Avatar>
            <Typography
              variant="h5"
              align="center"
              mt={3}
              mb={4}
              color="#333333"
            >
              Giriş Yap
            </Typography>

            <Box component="form" onSubmit={handleSubmit}>
              <TextField
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: "#666666" }} /> {/* İkon rengi */}
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                value={values.email}
                fullWidth
                required
                sx={{
                  mb: 3,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                    backgroundColor: "transparent", // Saydam arka plan
                    "& fieldset": {
                      borderColor: "#cccccc", // Kenarlık rengi
                    },
                    "&:hover fieldset": {
                      borderColor: "#0092CA", // Hover durumunda kenarlık rengi
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#007BAA", // Fokus durumunda kenarlık rengi
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#333333", // Metin rengi
                  },
                  "& .MuiInputLabel-root": {
                    color: "#666666", // Label rengi
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#007BAA", // Fokus durumunda label rengi
                  },
                }}
              />
              <TextField
                label="Şifre"
                name="password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon sx={{ color: "#666666" }} />{" "}
                      {/* İkon rengi */}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={() => setShowPassword((prev) => !prev)}
                        sx={{
                          minWidth: "auto",
                          padding: 0,
                        }}
                      >
                        {showPassword ? (
                          <VisibilityOff sx={{ color: "#666666" }} /> // Gizli şifre ikonu rengi
                        ) : (
                          <Visibility sx={{ color: "#666666" }} /> // Şifre görünür ikonu rengi
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                value={values.password}
                fullWidth
                required
                sx={{
                  mb: 3,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 3,
                    backgroundColor: "transparent", // Saydam arka plan
                    "& fieldset": {
                      borderColor: "#cccccc", // Kenarlık rengi
                    },
                    "&:hover fieldset": {
                      borderColor: "#0092CA",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#007BAA",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#333333", // Metin rengi
                  },
                  "& .MuiInputLabel-root": {
                    color: "#666666",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#007BAA",
                  },
                }}
              />

              <Box
                onClick={handleForgot}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#0092CA",
                  mb: 3,
                  "&:hover": {
                    textDecoration: "none",
                    color: "#007BAA",
                  },
                }}
              >
                Şifremi Unuttum
              </Box>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                fullWidth
                sx={{
                  backgroundColor: "#0092CA",
                  color: "white",
                  borderRadius: 3,
                  paddingY: 1.5,
                  boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.2)",
                  textTransform: "none",
                  fontSize: "1rem",
                  "&:hover": {
                    backgroundColor: "#007BAA",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Giriş Yap"
                )}
              </Button>
            </Box>

            <Box sx={{ mt: 3, textAlign: "center" }}>
              <Typography variant="body2" color="#666666">
                Eğer henüz hesabınız yoksa{" "}
                <a
                  href="https://www.musavironline.com/api/authentication/register"
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: "#0092CA",
                    cursor: "pointer",
                  }}
                >
                  tıklayınız!
                </a>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
