import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
  Checkbox,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import ExportData from "../Commons/ExportData";

const KarsitIncelemeIslemCell = ({
  aliciVknTckn,
  saticiVknTckn,
  year,
}) => {
  const [selectedMonths, setSelectedMonths] = useState([]);

  const months = [
    { value: "Hayır", label: "Hayır" },
    { value: "All", label: "Tümü" },
    { value: "Ocak", label: "Ocak" },
    { value: "Şubat", label: "Şubat" },
    { value: "Mart", label: "Mart" },
    { value: "Nisan", label: "Nisan" },
    { value: "Mayıs", label: "Mayıs" },
    { value: "Haziran", label: "Haziran" },
    { value: "Temmuz", label: "Temmuz" },
    { value: "Ağustos", label: "Ağustos" },
    { value: "Eylül", label: "Eylül" },
    { value: "Ekim", label: "Ekim" },
    { value: "Kasım", label: "Kasım" },
    { value: "Aralık", label: "Aralık" },
  ];

  const monthMap = {
    "Ocak": "01",
    "Şubat": "02",
    "Mart": "03",
    "Nisan": "04",
    "Mayıs": "05",
    "Haziran": "06",
    "Temmuz": "07",
    "Ağustos": "08",
    "Eylül": "09",
    "Ekim": "10",
    "Kasım": "11",
    "Aralık": "12",
  };

  const handleChange = (event) => {
    const value = event.target.value;

    if (value.includes("All")) {
      setSelectedMonths(months.slice(2).map((month) => month.value));
    } else if (value.includes("Hayır") && value.length > 1) {
      setSelectedMonths(value.filter((item) => item !== "Hayır"));
    } else {
      setSelectedMonths(value);
    }
  };

  const resolvedMonth =
    selectedMonths.length > 0 && !["All", "Hayır"].includes(selectedMonths[0])
      ? monthMap[selectedMonths[0]]
      : "01"; // Varsayılan ay Ocak

  const extraMonths =
    selectedMonths.length > 1 && !selectedMonths.includes("All")
      ? selectedMonths
          .slice(1) // İlk ay dışında kalanları al
          .map((month) => `&extra_months=${monthMap[month]}`) // Ayları numaralara çevir
          .join("")
      : "";

  const allYearSales = selectedMonths.includes("All");

  return (
    <>
      <TableCell
        sx={{
          padding: "10px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          minWidth: "90px",
          maxWidth: "90px",
        }}
        title="'Tümü' seçili olursa tüm dönemler dahil edilir, ay seçerek çoklu seçim yapabilirsiniz!"
        align="center"
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <Select
            multiple
            displayEmpty
            value={selectedMonths}
            onChange={handleChange}
            renderValue={(selected) => {
              if (selected.length === 0) return "Hayır";
              return selected.join(", ");
            }}
          >
            {months.map((month) => (
              <MenuItem key={month.value} value={month.value}>
                <Checkbox checked={selectedMonths.indexOf(month.value) > -1} />
                <ListItemText primary={month.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell
        sx={{
          padding: "10px",
          minWidth: "50px",
          maxWidth: "90px",
        }}
      >
        <ExportData
          url={`api/indirilecek_KDV/export_karsit_inceleme_list/?saticiVknTckn=${
            saticiVknTckn || ""
          }&aliciVknTckn=${aliciVknTckn || ""}&year_month=${year || "2024"}-${
            resolvedMonth
          }${
            allYearSales
              ? "&all_year_sales=True"
              : `&all_year_sales=False${extraMonths}`
          }`}
          dosyaAdi={`karsit_inceleme_listesi.docx`}
        />
      </TableCell>
    </>
  );
};

export default KarsitIncelemeIslemCell;
