import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MyButton from "../Commons/MyButton";
import useAxios from "../../hooks/useAxios";
import JSZip from "jszip";
import { notify } from "../../helper/notify";

const message = {
  upload: "Dosyalarınız Yükleniyor...",
  process: "Dosyalarınız işleniyor...",
};

const MAX_FILES = 250; // Maximum number of files allowed
const MAX_TOTAL_SIZE_MB = 100; // Maximum total file size allowed in MB
const MAX_TOTAL_SIZE_BYTES = MAX_TOTAL_SIZE_MB * 1024 * 1024; // Convert MB to bytes

const UploadFilesForm = ({
  setData,
  tckn,
  url,
  secondUrl,
  folderType,
  title,
  getIslenmisFaturalar,
}) => {
  const [open, setOpen] = useState(false);
  const [fileBatchError, setFileBatchError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const axiosWithToken = useAxios();

  const successSound = new Audio("/success.mp3");
  const errorSound = new Audio("/error.mp3");

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setFileBatchError("");
    setSelectedFiles([]);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    validateFiles(files);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = () => setDragActive(false);

  const handleDrop = (e) => {
    e.preventDefault();
    setDragActive(false);
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
    validateFiles(files);
  };

  const validateFiles = (files) => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (files.length > MAX_FILES) {
      setFileBatchError(
        `Maksimum ${MAX_FILES} dosya ve maksimum 100mb'a kadar dosya yükleyebilirsiniz. Lütfen fazlalıkları kaldırınız.`
      );
    } else if (totalSize > MAX_TOTAL_SIZE_BYTES) {
      setFileBatchError(
        `Toplam dosya boyutu ${MAX_TOTAL_SIZE_MB} MB'ı aşamaz.`
      );
    } else {
      setFileBatchError("");
    }
  };

  const handleRemoveFile = (index) => {
    if (!loading) {
      const updatedFiles = selectedFiles.filter((_, i) => i !== index);
      setSelectedFiles(updatedFiles);
      validateFiles(updatedFiles);
    }
  };

  // const processFiles = async () => {
  //   const zip = new JSZip();
  //   const zipFiles = [];
  //   const xmlFiles = [];

  //   selectedFiles.forEach((file) => {
  //     if (file.name.endsWith(".xml")) {
  //       xmlFiles.push(file);
  //     } else if (file.name.endsWith(".zip")) {
  //       zipFiles.push(file);
  //     }
  //   });

  //   if (xmlFiles.length > 0) {
  //     xmlFiles.forEach((file) => {
  //       zip.file(file.name, file);
  //     });
  //     const newZipBlob = await zip.generateAsync({ type: "blob" });
  //     zipFiles.push(new File([newZipBlob], "merged_files.zip"));
  //   }

  //   return zipFiles;
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (fileBatchError || selectedFiles.length === 0) {
  //     notify("Lütfen geçerli dosyalar seçiniz ve yeniden deneyin.", "error");
  //     errorSound.play(); // Hata sesi çal
  //     return;
  //   }

  //   setLoading("upload");

  //   try {
  //     const processedFiles = await processFiles();
  //     const zip = new JSZip();
  //     processedFiles.forEach((file, index) => {
  //       zip.file(file.name, file);
  //     });

  //     const finalZipBlob = await zip.generateAsync({ type: "blob" });
  //     const data = new FormData();
  //     data.append("file", new File([finalZipBlob], "final_files.zip"));
  //     data.append("tckn", tckn);
  //     data.append("folder_type", folderType);
  //     console.log(selectedFiles);
  //     console.log(finalZipBlob);
  //     const res = await axiosWithToken.post(url, data);
  //     console.log(res);
  //     console.log(data);
  //     if (res.status === 200) {
  //       setLoading("process");
  //       const response = await axiosWithToken.post(secondUrl, {
  //         tckn,
  //         folder_type: folderType,
  //       });

  //       if (response.status === 200) {
  //         handleApiResponse(response);
  //         notify("Dosyalarınız başarıyla yüklendi ve işlendi.", "success");
  //         successSound.play(); // Başarı sesi çal
  //       } else {
  //         notify(
  //           "Dosyalar yüklendi ancak veri işleme sırasında bir hata oluştu!",
  //           "error"
  //         );
  //         errorSound.play(); // Hata sesi çal
  //       }
  //     } else {
  //       notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
  //       errorSound.play(); // Hata sesi çal
  //     }

  //     handleClose();
  //   } catch (error) {
  //     notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
  //     errorSound.play(); // Hata sesi çal
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const processFiles = async () => {
    const zip = new JSZip();

    selectedFiles.forEach((file) => {
      if (file.name.endsWith(".xml")) {
        zip.file(file.name, file); // XML dosyalarını ekle
      } else if (file.name.endsWith(".zip")) {
        zip.file(file.name, file); // ZIP dosyalarını olduğu gibi bırak
      }
    });

    // Nihai zip dosyasını oluştur ve MIME tipini belirle
    const finalZipBlob = await zip.generateAsync({ type: "blob" });

    // Yeni bir dosya nesnesi oluştururken MIME tipini belirtin
    const zipFile = new File([finalZipBlob], "final_files.zip", {
      type: "application/zip",
    });

    console.log("Oluşturulan dosya tipi:", zipFile.type); // Kontrol için log

    return zipFile; // Nihai dosyayı döndür
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (fileBatchError || selectedFiles.length === 0) {
      notify("Lütfen geçerli dosyalar seçiniz ve yeniden deneyin.", "error");
      errorSound.play(); // Hata sesi çal
      return;
    }

    setLoading("upload");

    try {
      // Nihai zip dosyasını hazırla
      const finalZipBlob = await processFiles();

      // Kontrol: Blob tipi
      console.log("Gönderilen dosya tipi: application/zip");
      console.log("Gönderilen Blob boyutu:", finalZipBlob.size);

      // Yeni dosyayı doğru MIME tipiyle oluştur
      const zipFile = new File([finalZipBlob], "final_files.zip", {
        type: "application/zip", // MIME tipini açıkça belirtiyoruz
      });

      const data = new FormData();
      data.append("files", zipFile); // Nihai zip dosyasını ekle
      data.append("tckn", tckn);
      data.append("folder_type", folderType);

      console.log("Gönderilen dosyalar:", selectedFiles);
      console.log("Gönderilen zip dosyası:", zipFile);

      // Backend'e isteği gönder
      const res = await axiosWithToken.post(url, data);
      console.log(res);
      console.log(data);

      if (res.status === 200) {
        setLoading("process");

        // İkinci URL'ye isteği gönder
        const response = await axiosWithToken.post(secondUrl, {
          tckn,
          folder_type: folderType,
        });

        if (response.status === 200) {
          handleApiResponse(response); // Yönlendirme eklendi
          notify("Dosyalarınız başarıyla yüklendi ve işlendi.", "success");
          successSound.play(); // Başarı sesi çal
        } else {
          notify(
            "Dosyalar yüklendi ancak veri işleme sırasında bir hata oluştu!",
            "error"
          );
          errorSound.play(); // Hata sesi çal
        }
      } else {
        notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
        errorSound.play(); // Hata sesi çal
      }

      handleClose();
    } catch (error) {
      console.error("Hata:", error);
      notify("Dosyalarınızın yüklenmesi sırasında bir hata oluştu!", "error");
      errorSound.play(); // Hata sesi çal
    } finally {
      setLoading(false);
    }
  };
  const handleApiResponse = async (response) => {
    if (folderType === "fatura") {
      if (getIslenmisFaturalar) {
        getIslenmisFaturalar();
      } else {
        setData({
          alislarTemelData: JSON.parse(response.data.alislar_temel),
          alislarIstisnaData: JSON.parse(response.data.alislar_istisna),
          satislarTemelData: JSON.parse(response.data.satislar_temel),
          satislarIstisnaData: JSON.parse(response.data.satislar_istisna),
        });
      }
    } else if (folderType === "gcb") {
      setData(JSON.parse(response.data.gcbler));
    } else if (folderType === "beyanname") {
      setData(true);
    }
  };

  return (
    <>
      <MyButton
        variant="contained"
        color="btnActiveColor"
        onClick={handleOpen}
        title={title}
      />
      <Dialog open={open}>
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2">Mükellef TCKN/VKN : {tckn}</Typography>
        </DialogTitle>

        <DialogContent>
          <Typography color="error">{fileBatchError}</Typography>
          <Box
            onClick={() =>
              !loading && document.getElementById("fileInput").click()
            }
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            sx={{
              border: dragActive ? "2px solid blue" : "2px dashed gray",
              padding: "16px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <Typography>Dosyaları sürükleyip bırakın veya tıklayın.</Typography>
            <input
              type="file"
              multiple
              // accept=".xml,.zip"
              accept={folderType === "yuklenim" ? ".xls,.xlsx,.csv" : ".xml,.zip"}
              style={{ display: "none" }}
              id="fileInput"
              onChange={handleFileChange}
              disabled={loading}
            />
          </Box>

          <Typography color="secondary.second" variant="body2">
            *Seçtiğiniz dosyaların işlenebilmesi için Yükle butonuna tıklayınız!
          </Typography>

          {selectedFiles.length > 0 && (
            <List
              className="myscrool"
              sx={{ maxHeight: "75px", overflowY: "scroll" }}
            >
              {selectedFiles.map((file, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(index)}
                      disabled={Boolean(loading)}
                    >
                      <CloseIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
          )}

          {fileBatchError && (
            <Typography color="error" variant="body2">
              {fileBatchError}
            </Typography>
          )}
          {loading ? (
            <>
              <MyButton
                size="normal"
                variant="contained"
                type="button"
                disabled
                startIcon={<CircularProgress />}
                title={message[loading]}
              />
              <Typography color="secondary.second" variant="body2">
                İşlem süresi dosyaların boyutuna ve sayısına göre değişkenlik
                gösterebilir. İşlem bittiğinde yükleme penceresi otomatik olarak
                kapanacaktır.
              </Typography>
            </>
          ) : (
            <MyButton
              size="normal"
              variant="contained"
              color="btnActiveColor"
              onClick={handleSubmit}
              title="Yükle"
              disabled={!!fileBatchError || selectedFiles.length === 0}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="btnColor"
            disabled={Boolean(loading)}
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFilesForm;
