import { Box, Grid, Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import MyButton from "../Commons/MyButton";
import { notify } from "../../helper/notify";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import { ihracSatisFaturalariColumns } from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
import GcbKaydi from "../Islemler/GcbKaydi";
import ProductCreationWizard from "../Islemler/ProductCreationWizard";
import AllGcb from "../Tables/AllGcb";
import DateRangecomp from "../Commons/DateRangecomp";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const IhracSatisFaturalariListesi = ({ tckn }) => {
  const [exportDate, setExportDate] = useState({});
  const [ihracSatisFaturalariListesi, setIhracSatisFaturalariListesi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const axiosWithToken = useAxios();

  const getData = async (startDate, endDate) => {
    setError(false);
    if (startDate && endDate) {
      setLoading(true);
      setExportDate({ startDate, endDate });
      setIhracSatisFaturalariListesi([]);
      try {
        const { data } = await axiosWithToken(
          `api/indirilecek_KDV/ihrac_kayitli_satis_report/?tckn=${tckn}&start_date=${startDate}&end_date=${endDate}`
        );
        setIhracSatisFaturalariListesi(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.data?.error);
        setIhracSatisFaturalariListesi([]);
      }
    } else {
      notify("Lütfen önce tarih aralığı belirleyiniz!", "warning");
    }
  };

  useEffect(() => {
    setIhracSatisFaturalariListesi([]);
  }, [tckn]);

  return (
    <Box sx={{ p: 1 }}>
      <Typography color="error" variant="body2" mb={1}>
        *Eğer daha önce bu işlemleri yapmadıysanız lütfen önce yükleme
        işlemlerini tamamlayınız!
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1, gap: 1 }}>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          {" "}
          <EnvanterKaydi tckn={tckn} />
        </Box>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <GcbKaydi tckn={tckn} />{" "}
        </Box>
      </Box>
      <DateRangecomp getData={getData} title="Satış Faturaları Listesi" />
      {loading ? <LoadingData /> : ""}
      {error ? <ErrorData error={error} /> : ""}
      {ihracSatisFaturalariListesi.length > 0 ? (
        <>
          <Box sx={{
              border: "1px solid",
              borderColor: "secondary.second",
              p: 1,
              mt: 2,
            }}>
            <TableComponent
              data={ihracSatisFaturalariListesi}
              tableColumns={ihracSatisFaturalariColumns}
              tckn={tckn}
            />
            <Grid container display={"flex"} justifyContent="end" spacing={1}>
              <Grid item>
                <ExportData
                  url={`/api/indirilecek_KDV/ihrac_kayitli_satis_report_export/?tckn=${tckn}&start_date=${exportDate?.startDate}&end_date=${exportDate?.endDate}`}
                  dosyaAdi={`ihrac_kayitli_satis_Faturalari_listesi-${exportDate?.startDate}-${exportDate?.endDate}.xlsx`}
                />
              </Grid>
              <Grid item>
                <MyButton
                  title={"Kapat"}
                  variant="contained"
                  color="btnActiveColor"
                  onClick={() => setIhracSatisFaturalariListesi([])}
                >
                  Kapat
                </MyButton>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        ""
      )}
      <Grid container justifyContent={"center"} mt={2} spacing={1}>
      
        <Grid item xs={10} lg={4}>
          {/* <MissingProducts data={missingProducts} message={message} /> */}
          <AllGcb currentTCKN={tckn} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IhracSatisFaturalariListesi;
