import React, { useState } from "react";
import PageHeader from "../components/Commons/PageHeader";
import MyButton from "../components/Commons/MyButton";
import { Alert, Box, Grid } from "@mui/material";
import CustomerRegistrationModal from "../components/Forms/CustomerForm";
import CustomerSelect from "../components/Commons/CustomerSelect";
import Loading from "../components/Commons/Loading";
import StokTable from "../components/Tables/StokTable";
import CustomersSalesPurchasesTable from "../components/Tables/CustomersSalesPurchasesTable";
import EslesmisUrunlerTable from "../components/Tables/EslesmisUrunlerTable";
import AylıkKDVTablo from "../components/Tables/AylıkKDVTablo";
import AllGcb from "../components/Tables/AllGcb";
import { useGetCustomersQuery } from "../services/customers";
import ExportData from "../components/Commons/ExportData";


const Home = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isLoading: loading } = useGetCustomersQuery();
  const [selectedMusteri, setSelectedMusteri] = useState("");
  const [counterUrun, setCounterUrun] = useState(0);

  if (loading) return <Loading />;
  return (
    <Box sx={{ p: 1,minHeight:"100vh" }}>
      <PageHeader text="İşlemler" />

      <Grid container spacing={3} flexDirection={"column"} mt={1}>
        <Grid item xs={8} md={3}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={handleOpen}
            title="Yeni Mükellef Kaydı Oluştur"
          />
        </Grid>
      </Grid>

      <Grid container flexDirection={"column"} mt={1}>
        <Grid item xs={12} md={5}>
          <CustomerSelect
            selectedMusteri={selectedMusteri}
            setSelectedMusteri={setSelectedMusteri}
            msg="Verilerini görüntülemek istediğiniz müşteriyi seçiniz veya arama yapmak için yazınız"
          />
        </Grid>
      </Grid>

      {!selectedMusteri && (
        <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
          Verilerini görüntülemek istediğiniz müşteriyi seçiniz veya arama yapmak için yazınız
        </Alert>
      )}
      {selectedMusteri && (
        <Grid container justifyContent={"center"} spacing={2} mt={1}>
          <Grid item xs={10} md={6}>
            <AylıkKDVTablo selectedMusteri={selectedMusteri} />
          </Grid>
          <Grid item xs={10} md={6}>
            <AllGcb currentTCKN={selectedMusteri.value} />
          </Grid>
        </Grid>
      )}

      {selectedMusteri && (
        <Grid container justifyContent={"center"} spacing={2} mt={1}>
          <Grid item xs={10} md={6}>
            <StokTable
              selectedMusteri={selectedMusteri}
              counterUrun={counterUrun}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <CustomersSalesPurchasesTable selectedMusteri={selectedMusteri} />
            <Box mt={1} display="flex" justifyContent="flex-end">
            <ExportData
                  url={`api/mukellef_yonetimi/export_cari/${selectedMusteri?.value}`}
                  dosyaAdi={`Cari-Tablosu-${selectedMusteri?.value}.xlsx`}
                />
            </Box>
          </Grid>
        </Grid>
      )}

      {selectedMusteri && (
        <EslesmisUrunlerTable
          tckn={selectedMusteri.value}
          setCounterUrun={setCounterUrun}
        />
      )}

      {open && (
        <CustomerRegistrationModal isVisible={open} toggleModal={handleClose} />
      )}
    </Box>
  );
};

export default Home;
