import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import MyButton from "../Commons/MyButton";
import useAuthCall from "../../hooks/useAuthCall";
import { useSelector } from "react-redux";

const PasswordChangeForm = () => {
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [new_password_confirm, setNewPasswordConfirm] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { changePassword } = useAuthCall();
  const { currentUser } = useSelector((state) => state.auth);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOldPassword("");
    setNewPassword("");
    setNewPasswordConfirm("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword({
      email: currentUser.email,
      old_password: oldPassword,
      new_password: newPassword,
      new_password_confirm: new_password_confirm,
    });
    handleClose();
  };

  const toggleVisibility = (field) => {
    if (field === "old") setShowOldPassword((prev) => !prev);
    if (field === "new") setShowNewPassword((prev) => !prev);
    if (field === "confirm") setShowConfirmPassword((prev) => !prev);
  };

  return (
    <div>
      <MyButton
        title="Şifre Değiştir"
        variant="contained"
        color="btnActiveColor"
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Şifre Değiştir</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="old-password"
              label="Eski Şifre"
              type={showOldPassword ? "text" : "password"}
              fullWidth
              variant="standard"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisibility("old")}>
                      {showOldPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              id="new-password"
              label="Yeni Şifre"
              type={showNewPassword ? "text" : "password"}
              fullWidth
              variant="standard"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisibility("new")}>
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="dense"
              id="new-password-confirm"
              label="Yeni Şifre Tekrarı"
              type={showConfirmPassword ? "text" : "password"}
              fullWidth
              variant="standard"
              value={new_password_confirm}
              onChange={(e) => setNewPasswordConfirm(e.target.value)}
              helperText={
                new_password_confirm && new_password_confirm !== newPassword
                  ? "Şifreler uyuşmuyor"
                  : ""
              }
              error={
                new_password_confirm && new_password_confirm !== newPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => toggleVisibility("confirm")}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions>
            <MyButton
              title="İptal"
              variant="contained"
              color="btnActiveColor"
              onClick={handleClose}
            />
            <MyButton
              title="Değiştir"
              disabled={new_password_confirm !== newPassword}
              type="submit"
              variant="contained"
              color="btnActiveColor"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default PasswordChangeForm;
