import React, { useMemo, useState } from "react";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import FullScreen from "../Commons/FullScreen";
import { useTheme, Modal, Box, Button } from "@mui/material";


const GlobalFilter = ({ filter, setFilter }) => {
  const theme = useTheme();
  return (
    <span style={{ color: `${theme.palette.secondary.second}` }}>
      Search:{" "}
      <input
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ fontSize: "1.1rem", marginBottom: "10px" }}
      />
    </span>
  );
};


const TableComponent = ({ data, tableColumns, onCellEdit, tckn, showFatura}) => {
  const columns = useMemo(() => tableColumns, []);
  const [filterInput, setFilterInput] = useState("");
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState("");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const handleFilterChange = (value) => {
    setGlobalFilter(value);
    setFilterInput(value);
  };
  
  const handleOpen = async (vkn, faturaNo) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/mukellef_yonetimi/invoice_detail_view/${vkn}/${faturaNo}/`
      );
      const invoiceHtml = await response.text();
      setCurrentInvoice(invoiceHtml);
      setOpen(true);
    } catch (error) {
      console.error("Fatura detayları alınamadı:", error);
      setCurrentInvoice(
        "<h2>Fatura detayları yüklenirken bir hata oluştu.</h2>"
      );
      setOpen(true);
    }
  };

  const handleClose = () => {
    setCurrentInvoice("");
    setOpen(false);
  };

  if (!data?.length) return <h3>Görüntülenecek veri bulunamadı!</h3>;

  return (
    <div>
      <FullScreen>
        <GlobalFilter filter={filterInput} setFilter={handleFilterChange} />
        <div
          className="myTable"
          style={{ overflowX: "auto", maxWidth: "100%" }}
        >
          <table {...getTableProps()} style={{ width: "100%" }}>
            <thead style={{ backgroundColor: "#0092CA", color: "white" }}>
              {headerGroups.map((headerGroup, hgIndex) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={`headerGroup-${hgIndex}`}
                >
                  { !showFatura && <th style={{ textAlign: "center" }}>Fatura Görüntüle</th>}
                  {headerGroup.headers.map((column, colIndex) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={`column-${column.id}-${colIndex}`}
                      style={{
                        padding: "10px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        minWidth: "75px",
                        maxWidth: "150px",
                        cursor: "pointer",
                      }}
                      title={
                        typeof column.Header === "string" ? column.Header : ""
                      }
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : "⇅"}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              style={{ color: `${theme.palette.secondary.second}` }}
            >
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`row-${rowIndex}`}>


                   { !showFatura && <td style={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleOpen(row.original["ALICI VKN/TCKN"] || row.original["Satıcının Vergi Kimlik Numarası / TC Kimlik Numarası"] || row.original["saticiVknTckn"] || tckn  , row.original["FATURA NO"] || row.original["Alış Faturasının Sıra No'su"] || row.original["faturaNo"] || row.original["Satış Faturasının Sıra No'su"] || row.original["İhraç Kayıtlı Satış Faturasının Sıra No’su"])                        
                        }
                        
                      >
                        Görüntüle
                      </Button>
                    </td>}
                    {row.cells.map((cell, cellIndex) => (
                      <td
                        {...cell.getCellProps()}
                        key={`cell-${cell.column.id}-${cellIndex}`}
                        style={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "75px",
                          maxWidth: "250px",
                        }}
                        title={cell.value}
                      >
                        {cell.column.isEditable ? (
                          <input
                            type="text"
                            value={cell.value || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d*\.?\d*$/.test(value)) {
                                onCellEdit(rowIndex, cell.column.id, value);
                              }
                            }}
                            style={{
                              width: "120px",
                              textAlign: "center",
                              height: "30px",
                            }}
                          />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: "10px" }}>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span style={{ color: `${theme.palette.secondary.second}` }}>
            Sayfa{" "}
            <strong>
              {pageIndex + 1} / {pageOptions.length}
            </strong>
          </span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 50].map((size) => (
              <option key={size} value={size}>
                Göster {size}
              </option>
            ))}
          </select>
        </div>
      </FullScreen>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80%",
            backgroundColor: "white",
            p: 4,
            borderRadius: "8px",
            boxShadow: 24,
            overflowY: "auto",
            maxHeight: "90vh",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: currentInvoice }} />
        </Box>
      </Modal>
    </div>
  );
};

export default TableComponent;
