import React, { useState, useEffect } from "react";
import { Box, Button, Step, StepLabel, Stepper, Typography, Grid } from "@mui/material";
import MyButton from "../Commons/MyButton";
import { notify } from "../../helper/notify";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import { yuklenilecekKDVColumns } from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
import GcbKaydi from "../Islemler/GcbKaydi";
import YuklenimKaydi from "../Islemler/YuklenimKaydi";
import LoadingData from "../Commons/LoadingData";
import DateComp from "../Commons/DateComp";
import ErrorData from "../Commons/ErrorData";
import UrunEslestirme from "../Forms/UrunEslestirme";
import IkinciTipYuklenim from "../Forms/IkinciTipYuklenimTable";

const YuklenilecekKDVListesi = ({ tckn }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [yuklenecekKDVData, setYuklenecekKDVData] = useState([]);
  const [exportDate, setExportDate] = useState({});
  const [missingProducts, setMissingProducts] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const axiosWithToken = useAxios();

  const getData = async (year, month) => {
    year = year ? year : exportDate?.year;
    month = month ? month : exportDate?.month;
    setError(false);
    setMissingProducts([]);

    if (year && month) {
      setExportDate({ year, month });
      setLoading(true);
      setYuklenecekKDVData([]);
      try {
        const { data } = await axiosWithToken(
          `api/yuklenilecek_kdv/yuklenim_arti_gcb_merge?tckn=${tckn}&year=${year}&month=${month}`
        );
        setYuklenecekKDVData(data.data || []);
        setMissingProducts(data.missing_products || []);
        setMessage(data.message || "");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.data?.error || "Bir hata oluştu");
        setYuklenecekKDVData([]);
        if (Array.isArray(error?.response?.data?.missing_products)) {
          setMissingProducts(error.response.data.missing_products);
        }
      }
    } else {
      notify("Lütfen önce tarih aralığı belirleyiniz!", "warning");
    }
  };

  useEffect(() => {
    setYuklenecekKDVData([]);
    setMissingProducts([]);
    setActiveStep(0); // Mükellef değiştiğinde adımı sıfırla
  }, [tckn]);

  const steps = [
    {
      label: "Fatura Kaydı Yükle",
      description: "Fatura kaydı yüklemek için bu adımı tamamlayın. Eğer tamamladıysanız devam et butonuna basabilirsiniz.",
      content: <EnvanterKaydi tckn={tckn} />,
    },
    {
      label: "GÇB Kaydı Yükle",
      description: "GÇB kaydı yüklemek için gerekli bilgileri sağlayın. Eğer tamamladıysanız devam et butonuna basabilirsiniz.",
      content: <GcbKaydi tckn={tckn} />,
    },
    {
      label: "Yüklenim Kaydı Yükle",
      description: "Yüklenim kaydı işlemlerini bu adımda gerçekleştirin. Eğer tamamladıysanız devam et butonuna basabilirsiniz.",
      content: <YuklenimKaydi tckn={tckn} />,
    },
    {
      label: "Yüklenilecek KDV Listesi Seçimi",
      description: "Yıl ve ay seçimi yaparak KDV listesini oluşturun. Eğer tamamladıysanız devam et butonuna basabilirsiniz.",
      content: (
        <DateComp
          getData={(year, month) => {
            getData(year, month);
          }}
          title="Yüklenilecek KDV Listesi"
        />
      ),
    },
  ];

  const handleStepClick = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography color="error" variant="body2" mb={2}>
        *Eğer daha önce bu işlemleri yapmadıysanız lütfen önce yükleme işlemlerini tamamlayınız!
      </Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              onClick={() => handleStepClick(index)}
              sx={{
                cursor: "pointer",
                "& .MuiStepIcon-root": {
                  fontSize: "2.5rem",
                  color: activeStep === index ? "primary.main" : "grey.500",
                },
              }}
            >
              <Typography
                variant={activeStep === index ? "h6" : "body1"}
                sx={{ fontWeight: activeStep === index ? "bold" : "normal" }}
              >
                {step.label}
              </Typography>
            </StepLabel>
            {activeStep === index && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary" mb={1}>
                  {step.description}
                </Typography>
                {step.content}
                <Box sx={{ mt: 2 }}>
                  {index < steps.length - 1 && (
                    <Button variant="contained" onClick={handleNext} sx={{ mr: 1 }}>
                      Devam Et
                    </Button>
                  )}
                  {index > 0 && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Geri
                    </Button>
                  )}
                </Box>
              </Box>
            )}
          </Step>
        ))}
      </Stepper>
      {loading && <LoadingData />}
      {error && <ErrorData error={error} />}
      {Array.isArray(yuklenecekKDVData) && yuklenecekKDVData.length > 0 && (
        <Box sx={{ mt: 3 }}>
          <TableComponent data={yuklenecekKDVData} tableColumns={yuklenilecekKDVColumns} />
          <Grid container spacing={2} mt={2}>
            <Grid item>
              <ExportData
                url={`api/yuklenilecek_kdv/yuklenim_arti_gcb_merge?tckn=${tckn}&year=${exportDate?.year}&month=${exportDate?.month}&export=excel`}
                dosyaAdi={`yuklenilecek_KDV_listesi-${exportDate?.month}-${exportDate?.year}.xlsx`}
              />
            </Grid>
            <Grid item>
              <MyButton
                title="Kapat"
                variant="contained"
                color="btnActiveColor"
                onClick={() => setYuklenecekKDVData([])}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} spacing={1}>
            <Grid item xs={12}>
              <UrunEslestirme currentTCKN={tckn} getData={getData} exportDate={exportDate} />
            </Grid>
            <Grid item xs={12}>
              <IkinciTipYuklenim
                currentTCKN={tckn}
                getData={getData}
                year={exportDate.year}
                month={exportDate.month}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default YuklenilecekKDVListesi;
